import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RelativeTimeFormat from 'relative-time-format';

const UserTrialDaysCounter = (props) => {
    const lang = useSelector((state) => state.window.locale);
    if (!lang) return null;

    if (!props.expirationDate) return null;
    const ending = Math.ceil((Date.parse(props.expirationDate) - Date.now()) / 1000 / 3600 / 24);

    // polyfill for non Intl supported browsers
    // should be removed when Intl will be fully supported
    if (Intl === undefined || typeof Intl.RelativeTimeFormat !== 'function') {
        /* eslint-disable import/no-dynamic-require */
        /* eslint-disable global-require */
        if (!lang) return '';
        const locale = require(`relative-time-format/locale/${lang}.json`);
        RelativeTimeFormat.addLocale(locale);
        const value = new RelativeTimeFormat(lang, {
            style: 'long' // "long" is the default. Other options: "short", "narrow".
        }).format(ending, 'day');
        return value;
    }

    try {
        const value = new Intl.RelativeTimeFormat(lang);
        return value.format(ending, 'day');
    } catch (err) {
        console.log(err);
        return null;
    }
};

export default UserTrialDaysCounter;

UserTrialDaysCounter.propTypes = {
    expirationDate: PropTypes.string
};
