import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NavSectionItem.module.scss';
import { WEB_IMGIX } from '../../../utils/variables';
import { FormattedMessage } from 'react-intl';
import LinkWithQuery from '../../Base/LinkWithQuery/LinkWithQuery';
import LocalisedTitle from '../../Base/LocalisedTitle/LocalisedTitle';

const NavSectionItem = ({ icon, title, slug, parentSlug, isNew, onClick, children }) => {
    const [hover, setHover] = useState(false);
    const handleMouseOver = () => {
        setHover(true);
    };
    const handleMouseOut = () => {
        setHover(false);
    };

    function renderSubcategoryIcon() {
        // if no icon is given, use fallback icon from the collection slug
        const defaultSlug = parentSlug ? `${parentSlug}-${slug}` : `${slug}-all`;
        const iconSlug = icon || `icon-${defaultSlug}.svg`;
        return (
            <img
                src={`${WEB_IMGIX}/${iconSlug}`}
                width={36}
                height={36}
                loading="lazy"
                className={`${styles.subcategoryIcon}`}
                alt=""
            />
        );
    }

    function renderTags() {
        // TODO remove when solved the overflow of long collection titles
        return null;
        /* eslint-disable no-unreachable */
        if (!children) {
            return (
                <span onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                    <LinkWithQuery
                        to={`/mockups/${slug}`}
                        preserveQuery={['color']}
                        onClick={onClick}
                        className={styles.subtitleNoHover}
                    >
                        <FormattedMessage id="global.mockups.browse" defaultMessage="browse all" />
                    </LinkWithQuery>
                </span>
            );
        }

        const items = children.map((item, index) => {
            const menuItems = [];
            if (index < 2) {
                menuItems.push(
                    <LinkWithQuery
                        to={`/mockups/${item.slug}`}
                        preserveQuery={['color']}
                        className={styles.subtitle}
                        onClick={onClick}
                        key={`dropdown-link-${slug}-${item.slug}`}
                    >
                        <LocalisedTitle
                            title={item.menuTitle}
                        />
                    </LinkWithQuery>
                );
                if (index < 1 && children.length > 1) {
                    menuItems.push(<span key={`dropdown-link-${item.slug}-separator`}>, </span>);
                }
            }
            if (menuItems.length > 0) return menuItems;
            return null;
        });

        if (children.length > 2) {
            items.push(<span key={`dropdown-link-${slug}-more-tags`}>, ...</span>);
        }

        return items.flat();
        /* eslint-enable no-unreachable */
    }

    return (
        <li key={slug} className={styles.wrapper}>
            <span onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                <LinkWithQuery
                    to={`/mockups/${slug}`}
                    onClick={onClick}
                    preserveQuery={['color']}
                    onMouseEnter={() => handleMouseOver}
                    onMouseLeave={handleMouseOut}
                >
                    {renderSubcategoryIcon()}
                </LinkWithQuery>
            </span>

            <div className={styles.content}>
                <div className={styles.titleWrapper}>
                    <LinkWithQuery
                        to={`/mockups/${slug}`}
                        preserveQuery={['color']}
                        activeClassName="active"
                        className={hover ? styles.subcategoryTitleHover : styles.subcategoryTitle}
                        onClick={onClick}
                    >
                        {title}
                    </LinkWithQuery>
                    {isNew && (
                        <sup className={styles.newBadge}>
                            {' '}
                            <FormattedMessage defaultMessage="NEW" id="global.nav.badge.new" />
                        </sup>
                    )}
                </div>
                <div className={styles.subtitleWrapper}>{renderTags()}</div>
            </div>
        </li>
    );
};

export default NavSectionItem;

NavSectionItem.propTypes = {
    onClick: PropTypes.func,
    parentSlug: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.node,
    isNew: PropTypes.bool,
    children: PropTypes.array
};
