import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';
import DropdownTrigger from './DropdownTrigger';
import DropdownContent from './DropdownContent';

class Dropdown extends Component {
    ref = null;

    constructor(props) {
        super(props);

        this.state = {
            active: false
        };
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.onWindowClick);
        window.addEventListener('touchstart', this.onWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.onWindowClick);
        window.removeEventListener('touchstart', this.onWindowClick);
    }

    onWindowClick = event => {
        const dropdownElement = this.ref;
        if (
            event.target !== dropdownElement &&
            !dropdownElement.contains(event.target) &&
            this.isActive()
        ) {
            this.hide();
        }
    };

    onToggleClick = event => {
        event.preventDefault();
        if (this.isActive()) {
            this.hide();
        } else {
            this.show();
        }
    };

    isActive() {
        return typeof this.props.active === 'boolean' ? this.props.active : this.state.active;
    }

    hide() {
        this.setState(
            {
                active: false
            },
            () => {
                if (this.props.onHide) {
                    this.props.onHide();
                }
            }
        );
    }

    show() {
        this.setState(
            {
                active: true
            },
            () => {
                if (this.props.onShow) {
                    this.props.onShow();
                }
            }
        );
    }

    render() {
        const { children, className, disabled } = this.props;
        const { active: isActive, onShow, onHide, removeElement, onDisabledClick, ...cleanProps } = this.props;
        // create component classes
        const active = this.isActive();

        // stick callback on trigger element
        const boundChildren = React.Children.map(children, child => {
            if (child.type === DropdownTrigger) {
                const originalOnClick = child.props.onClick;
                child = cloneElement(child, {
                    ref: 'trigger',
                    onClick: (event, ...args) => {
                        if (!disabled) {
                            this.onToggleClick(event);
                            if (originalOnClick) {
                                originalOnClick.apply(child, event, ...args);
                            }
                        } else {
                            this.props.onDisabledClick?.();
                        }
                    }
                });
            } else if (child.type === DropdownContent && removeElement && !active) {
                child = null;
            }
            return child;
        });
       
        return (
            <div
                ref={c => (this.ref = c)}
                {...cleanProps}
                className={`${styles.dropdown} ${active ? styles.active : ''} ${
                    disabled ? styles.disabled : ''
                } ${className}`}
            >
                {boundChildren}
            </div>
        );
    }
}

Dropdown.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    onDisabledClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    removeElement: PropTypes.bool,
    style: PropTypes.object
};

Dropdown.defaultProps = {
    className: ''
};

export { DropdownTrigger, DropdownContent };
export default Dropdown;
