import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

const SsrFormattedMessage = (props) => {
    const { defaultMessage, values } = props;

    let rewrittenTittle = defaultMessage;

    if (values) {
        Object.keys(values).forEach((key) => {
            if (values[key] instanceof Object) {
                throw TypeError(`HTML element must be written like string in props: ${key}`);
            }

            rewrittenTittle = rewrittenTittle.replace(`{${key}}`, values[key]);
        });
    }

    return ReactHtmlParser(DOMPurify.sanitize(rewrittenTittle));
};

SsrFormattedMessage.propTypes = {
    defaultMessage: PropTypes.string.isRequired,
    values: PropTypes.object
};

export default SsrFormattedMessage;
