import React from 'react';
import PropTypes from 'prop-types';
import LinkLocalised from '../LinkLocalised/LinkLocalised';
import Router from 'next/router';

const LinkWithQuery = (props) => {
    const currentQuery = Router.router ? Router.router.query : null;
    const newQuery = new URLSearchParams();

    if (currentQuery) {
        for (const [key, value] of Object.entries(currentQuery)) {
            if (props.preserveQuery === 'all' || props.preserveQuery.indexOf(key) > -1) {
                newQuery.append(key, value);
            }
        }
    }

    if (props.query) {
        Object.keys(props.query).forEach((key) => {
            if (props.query[key] && props.query[key].length > 0) {
                newQuery.append(key, props.query[key]);
            } else {
                newQuery.delete(key);
            }
        });
    }

    let to = props.to;
    if (newQuery.toString().length > 0) {
        to += `?${newQuery.toString()}`;
    }
    return (
        <LinkLocalised className={props.className} style={props.style} to={to}>
            {props.children}
        </LinkLocalised>
    );
};

LinkWithQuery.propTypes = {
    to: PropTypes.string.isRequired,
    query: PropTypes.object,
    preserveQuery: PropTypes.oneOfType([PropTypes.oneOf(['all', 'none']), PropTypes.array])
};

export default LinkWithQuery;
