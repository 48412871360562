import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../../components/Layout/Footer/Footer';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

const PremiumModalContainerAsync = dynamic(() => import('./PremiumModalContainer'));

const FooterContainer = (props) => {
    const premiumModal = useSelector((state) => state.window.premiumModal);
    return (
        <>
            {!props.hideContent && <Footer />}

            {premiumModal && (
                <Suspense fallback={null}>
                    <PremiumModalContainerAsync />
                </Suspense>
            )}
        </>
    );
};

export default FooterContainer;

FooterContainer.propTypes = {
    hideContent: PropTypes.bool
};
