import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './UserTrialSlider.module.scss';

export default class UserTrialSlider extends Component {
    render() {
        let width = 0;
        if (this.props.expirationDate) {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const now = new Date();
            const ending = new Date(this.props.expirationDate);
            const diffDays = Math.round(Math.abs((now.getTime() - ending.getTime()) / oneDay));
            width = Math.min(Math.round((diffDays / 7) * 100), 100); //7 days trial, max 100%
        }
        return (
            <div className={styles.userTrialSliderTrack}>
                <div className={styles.userTrialSlider} style={{ width: `${width}%` }} />
            </div>
        );
    }
}

UserTrialSlider.propTypes = {
    expirationDate: PropTypes.string
};
