import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownContent, DropdownTrigger } from '../../Base/Dropdown/Dropdown';
import { trackEvent } from '../../../utils/trackingParty';
import styles from './Nav.module.scss';
import NavSectionItem from './NavSectionItem';
import { isIos } from '../../../utils/featureDetector';
import LocalisedTitle from '../../Base/LocalisedTitle/LocalisedTitle';
import { withRouter } from 'next/router';
import LinkWithQuery from '../../Base/LinkWithQuery/LinkWithQuery';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const NavSection = (props) => {
    let dropdown = null;
    let menuItem = null;

    const { category } = props;

    const { screenMD } = useWindowDimensions();

    const initialState = {
        dropdownLeft: 0,
        dropdownTop: 0
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    const getDropdownPosition = () => {
        const parentPosition = menuItem.getBoundingClientRect();

        let dropdownLeft = Math.max(Math.round(parentPosition.left), 0);
        if (dropdownLeft + 200 > window.innerWidth) {
            dropdownLeft = window.innerWidth - 200;
        }

        let dropdownTop = Math.round(parentPosition.top + parentPosition.height);

        // this hack fixes a iOS safari bug, where getBoundingClientRect returns negative values
        if (isIos()) {
            dropdownTop = Math.max(dropdownTop, 54 + 49);
        }

        setState({
            dropdownLeft,
            dropdownTop
        });
    };

    const handleMouseOver = () => {
        if (!screenMD) {
            getDropdownPosition();
            dropdown.show();
        }
    };

    const handleMouseOut = () => {
        if (dropdown.isActive && !screenMD) {
            dropdown.hide();
        }
    };

    const handleTriggerClick = () => {
        getDropdownPosition();
    };

    const handleItemClick = (slug) => {
        dropdown.hide();
        trackEvent('Subcategory open', {
            source: 'navbar',
            subcategory: slug
        });
    };

    const renderItems = () => {
        return category.children.map((subcategory) => {
            return (
                <NavSectionItem
                    title={
                        <LocalisedTitle
                            title={subcategory.menuTitle}
                        />
                    }
                    icon={subcategory.icon}
                    isNew={subcategory.isNew}
                    parentSlug={subcategory.parent}
                    slug={subcategory.slug}
                    key={`nav-section-item-${category.slug}-${subcategory.slug}`}
                    onClick={() => {
                        handleItemClick(subcategory.slug);
                    }}
                >
                    {subcategory.children}
                </NavSectionItem>
            );
        });
    };

    // single item category
    if (category.children.length === 1) {
        const subcategory = category.children[0];

        return (
            <li key={subcategory.slug} className={styles.navItem}>
                <LinkWithQuery
                    to={`/mockups/${category.slug}/${subcategory.slug}`}
                    preserveQuery={['preview', 'previewCleared', 'color']}
                    activeClassName="active"
                    className={styles.navLink}
                >
                    <LocalisedTitle
                        title={subcategory.menuTitle}
                    />
                </LinkWithQuery>
            </li>
        );
    }

    return (
        <li
            ref={(c) => {
                menuItem = c;
            }}
            className={styles.navItem}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
        >
            <Dropdown ref={(c) => (dropdown = c)} className={styles.dropdown}>
                <DropdownTrigger onClick={handleTriggerClick} className={styles.navSeparator} tabIndex={0}>
                    <LocalisedTitle
                        title={category.menuTitle}
                    />
                </DropdownTrigger>
                <DropdownContent
                    className={
                        category.children.length > 6
                            ? styles.dropdownContentTwoCols
                            : styles.dropdownContent
                    }
                    style={{
                        left: `${state.dropdownLeft}px`,
                        right: 'auto',
                        top: `${state.dropdownTop}px`
                    }}
                >
                    <ul
                        className={
                            category.children.length > 6
                                ? styles.dropdownListTwoCols
                                : styles.dropdownList
                        }
                    >
                        {renderItems()}
                    </ul>
                </DropdownContent>
            </Dropdown>
        </li>
    );
};

export default withRouter(NavSection);

NavSection.propTypes = {
    category: PropTypes.object
};
