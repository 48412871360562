import {useSelector} from "react-redux";

const useMenu = () => {
    const locale = useSelector((state) => state.window.locale);
    const localeMenu = useSelector((state) => state.window.menuItems);
    const ssrLocaleMenu = useSelector((state) => state.server.menuItems);

    const menuItems = locale ? localeMenu : ssrLocaleMenu;

    return {
        menuItems
    };
};

export default useMenu;

