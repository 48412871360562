import React, { useReducer } from 'react';
import styles from './NoUserLogin.module.scss';
import Button from '../../Base/Button/Button';
import { FormattedMessage } from 'react-intl';
import Modal, { ModalScroll } from '../../Base/Modal/Modal';
import Heading from '../../Base/Heading/Heading';
import LoginContainer from '../../../containers/Login/LoginContainer';

const NoUserLogin = () => {
    const initialState = {
        modalOpen: false,
        modalFirstQuestion: false,
        modalSecondQuestion: false,
        loading: false
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    const handleModalOpen = () => {
        setState({ modalOpen: true });
    };

    const handleModalClose = () => {
        setState({ modalOpen: false });
    };

    const renderLoginModal = () => {
        return (
            <Modal
                isOpen={state.modalOpen}
                modalLabel="Set a password"
                onClose={handleModalClose}
                size="small"
            >
                <ModalScroll>
                    <Heading level={3} size="sm" className="text-center">
                        <FormattedMessage defaultMessage="Sign In" />
                    </Heading>
                    <Heading level={2} className="text-center m-b-sm">
                        <FormattedMessage
                            defaultMessage="Welcome back!{newline}Sign into your account"
                            values={{ newline: <br /> }}
                        />
                    </Heading>

                    <LoginContainer isCompact />
                </ModalScroll>
            </Modal>
        );
    };

    return (
        <>
            {renderLoginModal()}
            <ul className={styles.wrapper}>
                <li className={styles.listButton}>
                    <Button size="small" className={styles.btn} onClick={() => handleModalOpen()}>
                        <FormattedMessage defaultMessage="Sign In" id="global.nav.label.signin" />
                    </Button>
                </li>
                <li className={styles.listButton}>
                    {/* <Link to="/signup" tabIndex={-1}>
                        <Button size="small" design="orange" className={styles.btn}>
                            <span className={styles.longLabel}>
                                <FormattedMessage
                                    defaultMessage="Get started for free"
                                    id="global.nav.label.signupLong"
                                />
                            </span>
                            <span className={styles.shortLabel}>
                                <FormattedMessage
                                    defaultMessage="Get started"
                                    id="global.nav.label.signupShort"
                                />
                            </span>
                        </Button>
                    </Link> */}
                </li>
            </ul>
        </>
    );
};

export default NoUserLogin;
