import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';
class DropdownContent extends Component {
    render() {
        const { children, className, position, isArrow, ...dropdownContentProps } = this.props;

        let positionClassName = '';

        if (position === 'left') {
            positionClassName = styles.dropdownContentLeft;
        }

        if (position === 'right') {
            positionClassName = styles.dropdownContentRight;
        }

        dropdownContentProps.className = `${styles.dropdownContent} ${positionClassName} ${
            isArrow ? styles.dropdownContentWithArrow : ''
        } ${className}`;

        return <div {...dropdownContentProps}>{children}</div>;
    }
}

DropdownContent.displayName = 'DropdownContent';

DropdownContent.propTypes = {
    position: PropTypes.oneOf(['center', 'left', 'right']),
    children: PropTypes.node,
    isArrow: PropTypes.bool,
    className: PropTypes.string
};

DropdownContent.defaultProps = {
    className: ''
};

export default DropdownContent;
