import { useSelector } from 'react-redux';
import { PLANS } from '../utils/variables';

const useUser = () => {
    const user = useSelector((state) => state.auth.user);
    const isProGifted = user && user.isTrial && user.disableTrialWatermark;
    const isPro = !!(
        (user && user.isPremium) ||
        (user && user.isTrial && user.disableTrialWatermark)
    );
    const isFree = !!((user && !user.isPremium) || (user && user.isTrial));
    const hasFederation = !!(user && !user.identities);
    const isUserInternal = !!(user && user.isAdmin);
    const isUserScheduled = !!(user && user.isScheduled);
    const isProYearly = !!(user && getPlan(PLANS.yearly, user.planId) !== undefined);
    const isProMonthly = !!(user && getPlan(PLANS.monthly, user.planId) !== undefined);

    function getPlan(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    return {
        ...user,
        isPro,
        isFree,
        isProGifted,
        hasFederation,
        isUserInternal,
        isUserScheduled,
        isProYearly,
        isProMonthly,
        isAuth: user !== null
    };
};

export default useUser;
