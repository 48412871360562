import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styles from './BfBanner.module.scss';
import CloseIcon from './CloseIcon.svg';
import Discount from './discount.svg';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import Button from '../../Base/Button/Button';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import usePlan from '../../../containers/Common/Plans/usePlan';
import { PLANS } from '../../../utils/variables';
import { trackEvent } from '../../../utils/trackingParty';
import useUser from "../../../hooks/useUser";
import useSeasonalDiscount from "../../../hooks/useSeasonalDiscount";

const BLACK_FRIDAY_BANNER = 'black-friday-banner';

const BfBanner = (props) => {
    const { isClosable, isHidden } = props;
    const { seasonalDiscount } = useSelector((state) => state.server);
    const { isProMonthly } = useUser();
    const { isActiveDiscount } = useSeasonalDiscount();

    const plan = {
        planType: 'pro',
        frequency: 'yearly'
    };

    const { planPrices } = usePlan(plan);
    const yearlyPlanId = PLANS.yearly[plan.planType];
    const yearlyAmount = seasonalDiscount;
    const symbol = planPrices[yearlyPlanId].symbol;

    const initialState = {
        visible: false,
        open: false
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    useEffect(() => {
        const hidden = localStorage.getItem('bfBanner');

        if (hidden === 'true') return;
        setState({ visible: true });
        setTimeout(() => {
            setState({ open: true });
        }, 800);
    }, [state.visible]);

    const handleHideClick = () => {
        localStorage.setItem('bfBanner', 'true');
        setState({ visible: false });
    };

    const renderCta = () => {
        return (
            <Button size="xxlarge" design="white" className="font-bold">
                <span role="img">🛍&nbsp;</span>
                {isProMonthly ? (
                    <FormattedMessage defaultMessage="Grab the deal" />
                ) : (
                    <FormattedMessage defaultMessage="Upgrade now" />
                )}
            </Button>
        );
    };

    const renderCloseButton = () => {
        return (
            <button className={`btn btn-icon ${styles.close}`} onClick={handleHideClick}>
                <Image src={CloseIcon} alt="close" layout="fill" />
            </button>
        );
    };

    const handleClick = () => {
        trackEvent(BLACK_FRIDAY_BANNER, {
            type: 'clicked'
        });
    };

    const shouldShowBanner = () => {
        return isActiveDiscount && isHidden !== true && state.visible;
    }

    if(!shouldShowBanner()) return null;

    return (
        <div className={`${styles.wrapper} ${state.open ? styles.open : null}`}>
            <Link to="/pricing" onClick={handleClick}>
                <div className={styles.center}>
                    <p className={styles.subtitle}>
                        <FormattedMessage defaultMessage="BLACK FRIDAY DEAL ALERT" />
                    </p>
                    <h1 className="font-bold">
                        <span role="img">🔥&nbsp;</span>
                        {isProMonthly ? (
                            <FormattedMessage
                                defaultMessage="Switch to annual billing and pay just {discount}/month"
                                values={{
                                    discount: `${symbol}${yearlyAmount.toFixed(2)}`
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Get your first year of PRO annual for just {discount}/month"
                                values={{
                                    discount: `${symbol}${yearlyAmount.toFixed(2)}`
                                }}
                            />
                        )}
                        <span role="img">&nbsp;🔥</span>
                    </h1>
                </div>

                <div className={styles.right}>{renderCta()}</div>

                {isClosable ? renderCloseButton() : null}

                <div className={styles.left}>
                    <Image src={Discount} alt="Discount Black Friday" width="118" height="118" />
                </div>
            </Link>
        </div>
    );
};

BfBanner.propTypes = {
    isClosable: PropTypes.bool,
    isHidden: PropTypes.bool
};

export default BfBanner;
