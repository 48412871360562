import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownContent, DropdownTrigger } from '../../Base/Dropdown/Dropdown';
import useUser from '../../../hooks/useUser';
import UserAvatar from './UserAvatar';
import UserTrialExpiration from './UserTrialExpiration';
import { FormattedMessage } from 'react-intl';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import styles from './UserMenu.module.scss';
import Button from '../../Base/Button/Button';

const UserMenu = (props) => {
    const dropdown = useRef();
    const user = useUser();

    const handleLinkClick = () => {
        dropdown.current.hide();
    };

    const handleLogoutClick = (e) => {
        dropdown.current.hide();
        props.onLogoutClick(e);
    };

    const handleUpgradeTrialClick = () => {
        dropdown.current.hide();
        props.onUpgradeTrialClick();
    };

    function renderTrialCountDown() {
        if (!props.user.isTrial) return null;

        if (user.isProGifted) return null;

        return (
            <UserTrialExpiration
                expirationDate={props.user.trialExpire}
                onClick={handleUpgradeTrialClick}
            />
        );
    }

    if (!props.user) return null;
    return (
        <div className={styles.wrapper}>
            <Dropdown ref={dropdown}>
                <DropdownTrigger>
                    <UserAvatar user={props.user} />
                </DropdownTrigger>
                <DropdownContent className={styles.dropdown} position="right">
                    <div className={styles.userName}>
                        <span>
                            <strong>
                                {props.user.given_name}&nbsp;{props.user.family_name}
                            </strong>
                        </span>
                        <br />
                        <span>{props.user.email}</span>
                    </div>
                    <Link
                        to="/user-settings/personal"
                        className={`font-regular ${styles.dropdownItem}`}
                        onClick={handleLinkClick}
                    >
                        <FormattedMessage defaultMessage="Account Settings" />
                    </Link>
                    <Link
                        to="/user-collections"
                        className={`font-regular ${styles.dropdownItem}`}
                        onClick={handleLinkClick}
                    >
                        <FormattedMessage
                            defaultMessage="Your Collections"
                            id="global.nav.label.userCollections"
                        />
                    </Link>
                    <Link
                        to="/history"
                        className={`font-regular ${styles.dropdownItem}`}
                        onClick={handleLinkClick}
                    >
                        <FormattedMessage
                            defaultMessage="Saved Mockups"
                            id="global.history.title"
                        />
                    </Link>
                    <li>
                        <a
                            onClick={handleLinkClick}
                            href="https://help.smartmockups.com"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.dropdownItem}
                        >
                            <FormattedMessage
                                defaultMessage="Get Help"
                                id="global.nav.label.getHelp"
                            />
                        </a>
                    </li>
                    {renderTrialCountDown()}

                    <Button
                        onClick={handleLogoutClick}
                        design="default"
                        size="small"
                        className={styles.logoutBtn}
                        isFull
                    >
                        <FormattedMessage defaultMessage="Sign out" />
                    </Button>
                </DropdownContent>
            </Dropdown>
        </div>
    );
};

UserMenu.propTypes = {
    user: PropTypes.object.isRequired,
    onLogoutClick: PropTypes.func,
    onUpgradeTrialClick: PropTypes.func
};

export default UserMenu;
