import React from 'react';
import PropTypes from 'prop-types';
import styles from './Container.module.scss';

const Container = (props) => {
    let className = styles.container;

    if (props.size === 'lg') {
        className = styles.containerLarge;
    }
    if (props.size === 'sm') {
        className = styles.containerSmall;
    }

    if (props.isPaddingDisabled) {
        className += ` ${  styles.noPadding}`;
    }

    if (props.className) {
        className += ` ${  props.className}`;
    }

    return <div className={className}>{props.children}</div>;
};

Container.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    isPaddingDisabled: PropTypes.bool,
    className: PropTypes.string
};

export default Container;
