import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './UserAvatar.module.scss';

export default class UserAvatar extends Component {
    render() {
        const { user } = this.props;
        let firstLeter = user.email.charAt(0);
        if (user.given_name) {
            firstLeter = user.given_name.charAt(0);
        }

        return (
            <div className={styles.wrapper}>
                {user.picture ? (
                    <img src={user.picture} className={styles.image} />
                ) : (
                    <div className={styles.icon}>{firstLeter}</div>
                )}
            </div>
        );
    }
}

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired
};
