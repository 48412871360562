import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import SsrFormattedMessage from "../SsrFormattedMessage/SsrFormattedMessage";

const LocalisedTitle = (props) => {

    if (props.values && !props.intlId) {
        return <SsrFormattedMessage defaultMessage={props.title} values={props.values} />
    }

    if (props.intlId) {
        const message = {
            id: props.intlId,
            defaultMessage: props.title,
            values: props.values ? props.values : {}
        };
        return <FormattedMessage {...message} />;
    }

    return <>{props.title}</>;
};
LocalisedTitle.propTypes = {
    title: PropTypes.string.isRequired,
    intlId: PropTypes.string,
    values: PropTypes.object
};

export default LocalisedTitle;
