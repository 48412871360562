import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import NavSection from './NavSection';
import styles from './Nav.module.scss';
import { FormattedMessage } from 'react-intl';

const Nav = (props) => {
    function renderNavSections() {
        return props.menuItems.map((category) => {
            return <NavSection category={category} key={`navitem${category.slug}`} />;
        });
    }

    return (
        <ul className={styles.navbar}>
            <li className={styles.navItem}>
                <Link
                    to="/mockups"
                    activeClassName={styles.navLinkActive}
                    className={styles.navLink}
                    tabIndex={0}
                >
                    <FormattedMessage defaultMessage="All mockups" />
                </Link>
            </li>
            {props.isUserPro ? (
                <li className={styles.navItem}>
                    <Link
                        to="/custom-mockups"
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                        tabIndex={0}
                    >
                        <FormattedMessage
                            defaultMessage="Custom"
                            id="global.nav.label.customMockupsShort"
                        />
                    </Link>
                </li>
            ) : null}
            {renderNavSections()}
        </ul>
    );
};

Nav.propTypes = {
    menuItems: PropTypes.array,
    isUserPro: PropTypes.bool
};

export default Nav;
