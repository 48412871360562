import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserTrialSlider from '../UserTrialSlider/UserTrialSlider';
import UserTrialDaysCounter from '../UserTrialDateCountdown/UserTrialDaysCounter';
import { FormattedMessage } from 'react-intl';
import styles from './UserTrialExpiration.module.scss';

export default class UserTrialExpiration extends Component {
    render() {
        if (!this.props.expirationDate) return null;
        return (
            <button onClick={this.props.onClick} className={styles.upgradeButton}>
                <div className={styles.wrapper}>
                    <div>
                        <FormattedMessage defaultMessage="Your trial expires" />
                    </div>
                    <UserTrialSlider expirationDate={this.props.expirationDate} />
                    <div>
                        <UserTrialDaysCounter expirationDate={this.props.expirationDate} />
                    </div>
                </div>
            </button>
        );
    }
}

UserTrialExpiration.propTypes = {
    expirationDate: PropTypes.string,
    onClick: PropTypes.func
};
