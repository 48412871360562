import { useEffect, useState } from 'react';
import { isServer } from '../utils/envDetection';

function getWindowDimensions() {
    const width = isServer() ? 1200 : window.innerWidth;

    const screenXSM = width <= 379 ?? true;
    const screenSM = width <= 767 ?? true;
    const screenMD = width <= 992 ?? true;
    const screenXMD = width <= 1019 ?? true;
    const screenLG = width <= 1200 ?? true;
    const screenXLG = width <= 1280 ?? true;
    const screenXL = width <= 1440 ?? true;
    const screenXXL = width <= 1850 ?? true;

    return {
        screenXSM,
        screenSM,
        screenMD,
        screenXMD,
        screenLG,
        screenXLG,
        screenXL,
        screenXXL
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
