import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import Icon from '../Icons/Icon';
import ReactModal from 'react-modal';
import ModalScroll from './ModalScroll';
import ModalSplitContent from './ModalSplitContent';

const Modal = (props) => {
    const [mounted, setMounted] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    useEffect(() => {
        setScrollbarWidth(getScrollbarWidth());
        if (props.isOpen) {
            setMounted(true);
            addBodyStyle();
        }
        return () => {
            removeBody();
        };
    }, [props.isOpen]);

    function getScrollbarWidth() {
        const { body } = document;
        const scrollDiv = document.createElement('div');

        let width = scrollbarWidth;

        // do this only if scrollbar is not already used
        if (body.offsetHeight < window.innerHeight) {
            // Append element with defined styling
            scrollDiv.setAttribute(
                'style',
                'width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;'
            );
            body.appendChild(scrollDiv);

            // Collect width & height of scrollbar
            const calculateValue = (type) =>
                scrollDiv[`offset${type}`] - scrollDiv[`client${type}`];
            width = calculateValue('Width');

            // Remove element
            body.removeChild(scrollDiv);
        }

        return width;
    }

    const handleRequestClose = () => {
        if (props.isOverlayClickDisabled) {
            return;
        }
        props.onClose();
    };

    function addBodyStyle() {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = 'hidden';
    }

    function removeBody() {
        document.body.style.paddingRight = '0';
        document.body.style.overflow = 'auto';
    }

    if (!mounted) return null;

    let modalClass;

    switch (props.size) {
        case `full`:
            modalClass = styles.modalFull;
            break;
        case `large`:
            modalClass = styles.modalLarge;
            break;
        case `medium`:
            modalClass = styles.modalMedium;
            break;
        case `small`:
            modalClass = styles.modalSmall;
            break;
        case `extraSmall`:
            modalClass = styles.modalExtraSmall;
            break;
        default:
            modalClass = styles.modalMedium;
    }

    let bgClass = styles.modalWhite;

    if (props.bg === 'black') {
        bgClass = styles.modalBlack;
    }

    let modalCentered;
    let modalPushed;

    if (props.isCentered) {
        modalCentered = styles.modalCentered;
        modalPushed = styles.modalPushed;
    }

    return (
        <ReactModal
            appElement={document.body}
            isOpen={props.isOpen}
            contentLabel={props.modalLabel}
            onRequestClose={handleRequestClose}
            className={`${modalClass} ${bgClass} ${modalPushed} ${props.className}`}
            overlayClassName={`${styles.overlay} ${modalCentered}`}
            closeTimeoutMS={300}
            style={{
                overlay: {
                    paddingRight: `${scrollbarWidth + 10}px`,
                    paddingLeft: `${10}px`
                }
            }}
        >
            <button className={styles.close} onClick={props.onClose}>
                <Icon name="Close" size="md" />
            </button>

            {props.children}
        </ReactModal>
    );
};

Modal.propTypes = {
    modalLabel: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isOverlayClickDisabled: PropTypes.bool,
    isCentered: PropTypes.bool,
    size: PropTypes.oneOf(['full', 'large', 'medium', 'small', 'extraSmall'])
};

export { ModalScroll, ModalSplitContent };
export default Modal;
