import React from 'react';
import style from './Heading.module.scss';

const Heading = ({ tag, level, size, children, className }) => {
    const HTag = tag ? tag : `h${level}`;

    let classNameString;

    switch (size) {
        case 'sm':
            classNameString = style.pageHeadlineSm;
            break;
        case 'lg':
            classNameString = style.pageHeadlineLg;
            break;
        case 'lgSmaller':
            classNameString = style.pageHeadlineLgSmaller;
            break;
    }

    return (
        <HTag className={`${classNameString} ${className ? className : undefined}`}>
            {children}
        </HTag>
    );
};

export default Heading;
