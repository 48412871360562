import { PLANS } from '../../../utils/variables';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const usePlan = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [plan, setPlan] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [planAmount, setPlanAmount] = useState(null);
    const [planSymbol, setPlanSymbol] = useState(null);
    const [planCurrency, setPlanCurrency] = useState(null);

    useEffect(() => {
        setPlanId(getPlanId(props.planType, props.frequency));
        setPlan(planTypeWithoutSeats(props.planType));
    }, [props.frequency, props.planType]);

    useEffect(() => {
        if (!planId) return;
        setPlanAmount(getPlanAmount(planId));
        setPlanSymbol(getPlanSymbol(planId));
        setPlanCurrency(getPlanCurrency(planId));
        setIsLoaded(true);
    }, [planId]);

    const planPrices = useSelector((state) => state.server.planPrices);

    function getPlanId(planType) {
        if (!planPrices || !props.frequency) return '';
        return PLANS[props.frequency][planType];
    }

    function getPlanAmount(planType) {
        if (planType === 'free') return 0;

        const planId = getPlanId(planType);

        if (!planPrices[planId]) return '';

        return planPrices[planId].amount;
    }

    function getPlanSymbol(planType) {
        const planId = getPlanId(planType);
        if (!planPrices[planId]) return '';
        return planPrices[planId].symbol;
    }

    function getPlanCurrency(planId) {
        if (!planPrices[planId]) return '';
        return planPrices[planId].currency;
    }

    function isCurrentPlan(currentPlan) {
        return getPlanId(props.planType) === currentPlan;
    }

    function planTypeWithoutSeats(planType) {
        return planType.replace(/[0-9]/g, '');
    }

    return {
        isLoaded,
        plan,
        planId,
        planAmount,
        planCurrency,
        planSymbol,
        planPrices,
        getPlanId,
        getPlanAmount,
        getPlanCurrency,
        getPlanSymbol,
        isCurrentPlan,
        planTypeWithoutSeats
    };
};

export default usePlan;
