import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderAlert from '../../../components/Base/Alerts/LoaderAlert/LoaderAlert';
import styles from './AlertsContainer.module.scss';
import LinkAlert from '../../../components/Base/Alerts/LinkAlert/LinkAlert';

class AlertsContainer extends Component {
    renderAlert(alertId, alertData, index) {
        const top = 100 + index * 80;
        if (alertData.type === 'loader') {
            return (
                <LoaderAlert
                    label={alertData.label}
                    progress={alertData.progress}
                    key={alertId}
                    top={top}
                />
            );
        }
        if (alertData.type === 'link') {
            return (
                <LinkAlert
                    label={alertData.label}
                    linkLabel={alertData.linkLabel}
                    link={alertData.link}
                    key={alertId}
                    top={top}
                />
            );
        }
        return null;
    }
    render() {
        const { alerts } = this.props;
        if (Object.keys(alerts).length === 0) return null;
        const items = [];

        Object.keys(alerts).map((alertId, index) => {
            items.push(this.renderAlert(alertId, alerts[alertId], index));
        });

        return <div className={styles.container}>{items}</div>;
    }
}

function mapStateToProps(state) {
    return {
        alerts: state.window.alerts
    };
}

export default connect(
    mapStateToProps,
    {}
)(AlertsContainer);
