import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';

class DropdownTrigger extends Component {
    render() {
        const { children, className, ...dropdownTriggerProps } = this.props;
        dropdownTriggerProps.className = `${styles.dropdownToggle} ${className}`;

        return (
            <span data-trigger="" {...dropdownTriggerProps}>
                {children}
            </span>
        );
    }
}

DropdownTrigger.displayName = 'DropdownTrigger';

DropdownTrigger.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

DropdownTrigger.defaultProps = {
    className: ''
};

export default DropdownTrigger;
