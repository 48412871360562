import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './LinkAlert.module.scss';
import Link from 'next/link';

export default class LinkAlert extends Component {
    render() {
        return (
            <div className={styles.wrapper} style={{ top: `${this.props.top}px` }}>
                <div className={styles.detailsRow}>
                    <div className={styles.label}>{this.props.label}</div>
                    <Link href={this.props.link}>
                        <a className={styles.link}>{this.props.linkLabel}</a>
                    </Link>
                </div>
            </div>
        );
    }
}

LinkAlert.propTypes = {
    label: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    linkLabel: PropTypes.node.isRequired,
    top: PropTypes.number.isRequired
};
