import React from 'react';
import styles from './ModalScroll.module.scss';
import PropTypes from 'prop-types';

const ModalScroll = props => {
    return (
        <div
            className={`${
                props.isPaddingDisabled ? styles.wrapperNoPadding : styles.wrapper
            } ${props.className && props.className}`}
        >
            {props.children}
        </div>
    );
};

export default ModalScroll;

ModalScroll.propTypes = {
    children: PropTypes.any.isRequired,
    isPaddingDisabled: PropTypes.bool
};
