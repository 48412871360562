import React, { Component } from 'react';

import PaymentIcons from './PaymentsIcons';

import Logo from '../Navbar/Logo';
import { gtag } from 'ga-gtag';
import styles from './Footer.module.scss';
import Container from '../../Base/Container/Container';
import Icon from '../../Base/Icons/Icon';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';
import Link from '../../Base/LinkLocalised/LinkLocalised';

const LanguagePicker = dynamic(
    () => import('../../../containers/Common/LanguagePicker/LanguagePicker'),
    { ssr: false }
);

export default class Footer extends Component {
    trackExternalLink(link) {
        gtag('event', {
            category: 'external link clicked',
            action: link
        });
    }

    render() {
        return (
            <footer>
                <Container>
                    <nav aria-labelledby="footer-navigation" className={styles.footer}>
                        <div className={styles.footerContent}>
                            <div className={styles.footerContentHolder}>
                                <div className={styles.footerContentTop}>
                                    <Link to="/">
                                        <Logo />
                                    </Link>
                                </div>
                                <div className={styles.footerContentBottom}>
                                    <div className={styles.languagePickerRow}>
                                        <div className={styles.languagePickerWrapper}>
                                            <LanguagePicker />
                                        </div>

                                        <ul className={styles.footerSocialNav}>
                                            <li className={styles.footerSocialNavItem}>
                                                <a
                                                    href="https://twitter.com/smartmockups"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Icon name="Twitter" color="grey" />
                                                </a>
                                            </li>
                                            <li className={styles.footerSocialNavItem}>
                                                <a
                                                    href="https://facebook.com/smartmockups"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Icon name="Facebook" color="grey" />
                                                </a>
                                            </li>
                                            <li className={styles.footerSocialNavItem}>
                                                <a
                                                    href="https://instagram.com/smartmockups"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Icon name="Instagram" color="grey" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className={styles.footerLowerContent}>
                                        &copy; Smartmockups, a Canva CZ s.r.o. brand - All rights reserved.  All product names, logos, and brands are property of their respective owners.
                                    </div>
                                    <div>
                                        <PaymentIcons
                                            width={216}
                                            height={21}
                                            className={styles.footerLowerPayment}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.footerCol}>
                            <div className={styles.footerNav}>
                                <input type="checkbox"/>

                                <div className={styles.footerNavTitle}>
                                    <h3>
                                        <FormattedMessage defaultMessage="Product" />
                                        <span><Icon name="ArrowDown" size="md" color="grey"/></span>
                                    </h3>
                                </div>

                                <ul>
                                    <li className={styles.footerNavItem}>
                                        <Link to="/features">
                                            <FormattedMessage
                                                defaultMessage="Features"
                                                id="global.nav.label.features"
                                            />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <Link to="/create">
                                            <FormattedMessage defaultMessage="Create" />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <Link to="/customers">
                                            <FormattedMessage defaultMessage="Customers" />
                                        </Link>
                                    </li>

                                    {/* <li className={styles.footerNavItem}>
                                        <Link to="/pricing">
                                            {' '}
                                            <FormattedMessage
                                                defaultMessage="Pricing"
                                                id="global.nav.label.pricing"
                                            />
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>

                        <div className={styles.footerCol}>
                            <div className={styles.footerNav}>
                                <input type="checkbox"/>

                                <div className={styles.footerNavTitle}>
                                    <h3>
                                        <FormattedMessage defaultMessage="Other" />
                                        <span><Icon name="ArrowDown" size="md" color="grey"/></span>
                                    </h3>
                                </div>

                                <ul>
                                    <li className={styles.footerNavItem}>
                                        <Link to="/privacy-terms-of-use">
                                            <FormattedMessage defaultMessage="Terms of Use" />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <Link to="/privacy-policy">
                                            <FormattedMessage defaultMessage="Privacy policy" />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <Link to="/cookies-policy">
                                            <FormattedMessage defaultMessage="Cookies policy" />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <Link to="/license">
                                            <FormattedMessage defaultMessage="License" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.footerCol}>
                            <div className={styles.footerNav}>
                                <input type="checkbox"/>

                                <div className={styles.footerNavTitle}>
                                    <h3>
                                        <FormattedMessage defaultMessage="Help" />
                                        <span><Icon name="ArrowDown" size="md" color="grey"/></span>
                                    </h3>
                                </div>

                                <ul>
                                    <li className={styles.footerNavItem}>
                                        <Link to="/frequently-asked-questions">
                                            <FormattedMessage defaultMessage="FAQs" />
                                        </Link>
                                    </li>

                                    <li className={styles.footerNavItem}>
                                        <a href="https://help.smartmockups.com" target="_blank" rel="noreferrer">
                                            <FormattedMessage defaultMessage="Knowledge Hub" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </Container>
            </footer>
        );
    }
}
