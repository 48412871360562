import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './LoaderAlert.module.scss';

export default class LoaderAlert extends Component {
    render() {
        const progress = Math.min(this.props.progress, 100);
        return (
            <div className={styles.wrapper} style={{ top: `${this.props.top}px` }}>
                <div className={styles.detailsRow}>
                    <div className={styles.label}>
                        {this.props.label}
                        <div className={styles.dots}>
                            <span className={styles.dot}>.</span>
                            <span className={styles.dot}>.</span>
                            <span className={styles.dot}>.</span>
                        </div>
                    </div>
                    <div className={styles.progress}>{progress}%</div>
                </div>
                <div className={styles.progressBarWrapper}>
                    <div className={styles.progressBar} style={{ width: `${progress}%` }} />
                </div>
            </div>
        );
    }
}

LoaderAlert.propTypes = {
    label: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired
};
