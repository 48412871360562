import useUser from "./useUser";
import {useSelector} from "react-redux";

const useSeasonalDiscount = () => {
    const { isFree, isProMonthly } = useUser();
    const { isSeasonalDiscount } = useSelector((state) => state.server);
    const isActiveDiscount = isSeasonalDiscount && (isFree || isProMonthly);
    const isActiveDiscountSubscriber = isSeasonalDiscount && isProMonthly;

    return {
        isActiveDiscount,
        isActiveDiscountSubscriber
    };
};

export default useSeasonalDiscount;
